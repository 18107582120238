import React, { useEffect, useState } from 'react'
import "./Analysing.css"
import { Button, Input } from 'antd'
import { ReactComponent as SendSvg } from '../assets/icons/send.svg';
import ReasoningPreset from './ReasoningPreset';
// import ReactMarkdown from 'react-markdown';
import SubHeader from './SubHeader';
import { DeleteOutlined} from '@ant-design/icons';
import { ReactComponent as GoodSvg } from '../assets/icons/GoodSvg.svg';
import { Icon } from "../utils/Icon";
import {ReactComponent as BadSvg} from '../assets/icons/BadSvg.svg';
import FeedbackPopup from './FeedbackPopup';
import MarkmapComponent from './MarkmapComponent';

const {TextArea} = Input

const GET_MINDMAP_PROMPT = `Please summarise key points of the given content. Please format the response in Markdown format with correct indentations. Follow the below given format precisely:

# Title

## Subtitle

1. First item
  - Sub-item
    - Sub-sub-item

2. Second item
  - Sub-item";`

// const initValue = `# markmap

// - beautiful
// - useful
// - easy
// - interactive
// `;

const Analysing = ({output, setOutput, inputMessage, setInputMessage, analysingGenerating, setAnalysingGenerating, feedback, setFeedback}) => {
    const [showPreset, setShowPreset] = useState(true);
    const [openFeedback, setOpenFeedback] = useState(false);
    useEffect(()=>{
        if (inputMessage !== "") {
            setShowPreset(false)
        }
    },[inputMessage]);

    const handleClick=(text)=>{
        setInputMessage(text)
    }
    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };
    const handleBlur = ()=>{
        if (inputMessage === "") {
            setShowPreset(true)
        }
    }

    const handleClearInput = ()=>{
        setInputMessage("")
        setShowPreset(true)
    }

    const handleGoodFeedback = ()=>{
        if (feedback.type !== 'good') {
            feedback.type = 'good';
            setOpenFeedback(true)
        }
      }
    
    const handleBadFeedback = () => {
        if (feedback.type !== 'bad') {
            feedback.type = 'bad';
            setOpenFeedback(true)
        }
    }

    const handleSubmit = async () => {
        setFeedback({type:'', detial:''})
        const url = process.env.REACT_APP_GET_REASONING_RESPONSE;
        setAnalysingGenerating(true)
        const mindMapInput = {"query": GET_MINDMAP_PROMPT + inputMessage, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false}
        const fetchAnalysingOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(mindMapInput),
        };
        let result = ""
        fetch(url, fetchAnalysingOptions).then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            function read() {
                return reader.read().then(({ done, value }) => {
                    if (done) {
                      return;
                    }
                    result += decoder.decode(value);
                    return read();
                  });
            }
            return read();
        }).then(()=>{
            result = processMarkdown(result)
            setAnalysingGenerating(false)
            setOutput(result);
        }).catch(e => {
            console.error(e)
            setAnalysingGenerating(false)
        })
        // setOutput(initValue);
    }

    // Process the result to be standard markdown
    const processMarkdown = (text)=>{
        let cleanedText = text.replace(/^```markdown\s*/,"");
        cleanedText = cleanedText.replace("```","");
        return cleanedText;
    }

    return (
        <div className='analysing-container'>
            <SubHeader />
            <div className='analysing-left-section'>
                <TextArea style={{resize:'none'}} onFocus={()=>{setShowPreset(false)}} onBlur={handleBlur} onChange={handleInputChange} value={inputMessage} className='analysing-input' rows={200}>
                </TextArea>
                {showPreset && <ReasoningPreset className="preset-body" handleClick={handleClick}/>}
                {inputMessage &&
                    <div className="analysing-clear-input-button-wrapper">
                        <Button className='clear-button' onClick={handleClearInput}>
                          Clear All
                          <DeleteOutlined style={{color:'#A72841'}}/>
                        </Button>
                    </div>
                }
                <Button
                    className='analysing-send-button'
                    type="text"
                    shape="circle"
                    loading={analysingGenerating}
                    icon={<SendSvg />}
                    size='large'
                    onClick={handleSubmit}/>
            </div>
            <div className='analysing-right-section'>
                <div className='analysing-output' id='analysing-output'>
                    <MarkmapComponent markdown={output} setMarkdown={setOutput}/>
                    {!analysingGenerating && output ?
                    <>
                        <div className='feedback-icon'>{
                            <div>
                            <Icon onClick={handleGoodFeedback} className='feedback-icon-item' size='13' style={{'paddingRight':'5px'}} color={feedback.type === 'good'?'#A72841':'grey'} icon={GoodSvg}/>
                            <Icon onClick={handleBadFeedback} className='feedback-icon-item' size='13' color={feedback.type === "bad"?'#A72841':'grey'} icon={BadSvg}/>
                            </div>}
                        </div>
                        {openFeedback && <FeedbackPopup prompt={inputMessage} answer={output} feedbackType={feedback.type} open={openFeedback} setOpen={setOpenFeedback} feedback={feedback} setFeedback={setFeedback} mode={"analysing"}/>}
                    {/* <div className="clear-button-wrapper">
                        <Button className='clear-button' onClick={() => setOutput("")}>
                          Clear history
                          <DeleteOutlined style={{color:'#A72841'}}/>
                        </Button>
                    </div> */}
                    </>:null}
                </div>
            </div>
        </div>
  )
}

export default Analysing;