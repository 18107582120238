import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ReactGA from "react-ga4";
// import {BrowserRouter as Router, Switch, Redirect} from 'react-router-dom'
import AppHeader from './components/Header';
import './App.css';
import Consultant from './components/Consultant';
import Retrieval from './components/Retrieval';
import Sidebar from './components/Sidebar';
import Drafting from './components/Drafting';
import Reasoning from './components/Reasoning';
import AppFooter from './components/Footer';
// import SubHeader from './components/SubHeader';
import Analysing from './components/Analysing';

function App() {
  useEffect(()=>{
    ReactGA.initialize("G-GES8S2DZMK");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "App.js" });
  },[])

  // Update WebFontConfig to use getMarkmap()
  window.WebFontConfig = {
    custom: {
        families: [
            "KaTeX_AMS",
            "KaTeX_Caligraphic:n4,n7",
            "KaTeX_Fraktur:n4,n7",
            "KaTeX_Main:n4,i4,i7",
            "KaTeX_Math:i4,i7",
            "KaTeX_Script",
            "KaTeX_SansSerif:n4,n7,i4",
            "KaTeX_Size1",
            "KaTeX_Size2",
            "KaTeX_Size3",
            "KaTeX_Size4",
            "KaTeX_Typewriter"
        ]
    },
    active: () => {
      const mm = window.mm; // Ensure Markmap is available globally
      if (mm && typeof mm.refreshHook === "function") {
          mm.refreshHook();
      }
    }
  };

  const [consultantMessages, setConsultantMessages] = useState([]);
  const [retrievalMessages, setRetrievalMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [output, setOutput] = useState("");
  const [result, setResult] = useState("");
  const [input, setInput] = useState("");
  const [reasoningGenerating, setReasoningGenerating] = useState(false)
  const [draftingGenerating, setDraftingGenerating] = useState(false)
  const [mode, setMode] = useState('General')
  const [reasoningFeedback, setreasoningFeedback] = useState({type:'', detail:''})
  const [draftingFeedback, setDraftingFeedback] = useState({type:'', detail:''})

  // Analysing
  const [analysingOutput, setAnalysingOutput] = useState("")
  const [analysingInput, setAnalysingInput] = useState("")
  const [analysingGenerating, setAnalysingGenerating] = useState(false)
  const [analysingFeedback, setAnalysingFeedback] = useState({type:'', detail:''})
  
  return (
    <div className='App'>
        <header>
          <AppHeader className='app-header' />
        </header>
        <main className='app-main' style={{padding:'0', margin:'0'}}>
          <><Sidebar/><AppFooter /></>
          <Routes>
              <Route path='/' element={<Navigate to='/consultant'/>}/>
              <Route path='/3101' element={<Navigate to='/consultant'/>}/>
              <Route path='/consultant' element={
                <Consultant messages={consultantMessages} setMessages={setConsultantMessages} mode={mode} setMode={setMode} />}/>
              <Route path='/retrieval' element={<Retrieval messages={retrievalMessages} setMessages={setRetrievalMessages} />}/>
              <Route path='/reasoning' element={
                <Reasoning output={output} setOutput={setOutput} inputMessage={inputMessage} setInputMessage={setInputMessage} reasoningGenerating={reasoningGenerating} setReasoningGenerating={setReasoningGenerating} feedback={reasoningFeedback} setFeedback={setreasoningFeedback}/>}/>
              <Route path='/drafting' element={
                <Drafting result={result} setResult={setResult} input={input} setInput={setInput} draftingGenerating={draftingGenerating} setDraftingGenerating={setDraftingGenerating} feedback={draftingFeedback} setFeedback={setDraftingFeedback}/>} />
              <Route path='/analysing' element={<Analysing output={analysingOutput} setOutput={setAnalysingOutput} inputMessage={analysingInput} setInputMessage={setAnalysingInput} analysingGenerating={analysingGenerating} setAnalysingGenerating={setAnalysingGenerating} feedback={analysingFeedback} setFeedback={setAnalysingFeedback}/>} />
          </Routes>
        </main>
    </div>
  );
}

export default App;