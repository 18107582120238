import React from 'react';
// import { Layout } from 'antd';
import './Footer.css';

// const { Footer } = Layout;

const AppFooter = () => {
    return (
        <div className='footer'>
            LegalExpert ©2023 Created by XtraBros
        </div>
    );
}

export default AppFooter;