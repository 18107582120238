import React, { useEffect, useState } from 'react'
import "./Reasoning.css"
import { Button, Input } from 'antd'
import { ReactComponent as SendSvg } from '../assets/icons/send.svg';
import ReasoningPreset from './ReasoningPreset';
import ReactMarkdown from 'react-markdown';
import SubHeader from './SubHeader';
import { DeleteOutlined} from '@ant-design/icons';
import { ReactComponent as GoodSvg } from '../assets/icons/GoodSvg.svg';
import { Icon } from "../utils/Icon";
import {ReactComponent as BadSvg} from '../assets/icons/BadSvg.svg';
import FeedbackPopup from './FeedbackPopup';
const {TextArea} = Input

const GET_SUMMARY_PROMPT = "Please give me a concise summary of the following content. ";

const Reasoning = ({output, setOutput, inputMessage, setInputMessage, reasoningGenerating, setReasoningGenerating, feedback, setFeedback}) => {
    const [showPreset, setShowPreset] = useState(true);
    const [openFeedback, setOpenFeedback] = useState(false);
    useEffect(()=>{
        if (inputMessage !== "") {
            setShowPreset(false)
        }
    },[inputMessage]);

    useEffect(()=>{
        var reasoning_output = document.getElementById('reasoning-output')
        if (reasoning_output.scrollHeight > reasoning_output.clientHeight) {
            reasoning_output.scrollTop = reasoning_output.scrollHeight
        }
    },[output])

    const handleSubmit= async ()=> {
        setFeedback({type:'', detial:''})
        const summary_url = process.env.REACT_APP_GET_REASONING_RESPONSE;
        const summaryInput = {"query": GET_SUMMARY_PROMPT + inputMessage, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false}
        // {"query": GET_SUMMARY_PROMPT + inputMessage}
        var check_summary = false
        var check_analysis = false
        setReasoningGenerating(true);
        const fetchSummaryOptions = {
          method: 'POST',
          headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(summaryInput),
        };
        const analysis_url = process.env.REACT_APP_GET_CHAT_RESPONSE;
        const analyseInput = {"query": inputMessage, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false}
        const fetchAnalysisOptions = {
          method: 'POST',
          headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(analyseInput),
        };
        setOutput('**Summary**\n\n');
        fetch(summary_url, fetchSummaryOptions).then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            function read() {
                return reader.read().then(async ({ done, value }) => {
                    if (done) {
                        check_summary = true
                        return;
                    }
                    const chunk = decoder.decode(value);
                    setOutput(prevOutput => prevOutput + chunk);
                    return read();
                });
            }
            return read();
        }).catch(error => {
            console.error('Error:', error);
            setOutput(prevOutput => prevOutput + 'Sorry, the request for summary fails. Please try agian later.');
        }).then(() => {
            // After the first request is finished, initiate the second request
            return fetch(analysis_url, fetchAnalysisOptions);
        }).then(response => {
            setOutput(prevOutput => prevOutput + '\n\n**Analysis**\n\n');
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            function read() {
                return reader.read().then(async ({ done, value }) => {
                    if (done) {
                        check_analysis = true
                        if (check_summary && check_analysis) {
                            setReasoningGenerating(false)
                        }
                        return;
                    }
                const chunk = decoder.decode(value);
                setOutput(prevOutput => prevOutput + chunk);
                return read();
                });
            }
            return read();
        })
        .catch(error => {
            console.error('Error:', error);
            setOutput(prevOutput => prevOutput + 'Sorry, the request for analysis fails. Please try agian later.');
            setReasoningGenerating(false);
        });
        
        // // Dummy output
        // setOutput("Dummy Output. Dummy Output. Dummy Output. Dummy Output. Dummy Output.")
        // setReasoningGenerating(false)
    }

    const handleClick=(text)=>{
        setInputMessage(text)
    }
    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };
    const handleBlur = ()=>{
        if (inputMessage === "") {
            setShowPreset(true)
        }
    }

    const handleClearInput = ()=>{
        setInputMessage("")
        setShowPreset(true)
    }

    const handleGoodFeedback = ()=>{
        if (feedback.type !== 'good') {
            feedback.type = 'good';
            setOpenFeedback(true)
        }
      }
    
    const handleBadFeedback = () => {
        if (feedback.type !== 'bad') {
            feedback.type = 'bad';
            setOpenFeedback(true)
        }
    }

    return (
        <div className='reasoning-container'>
            <SubHeader />
            <div className='reasoning-left-section'>
                <TextArea className='reasoning-input' style={{resize:'none'}} onFocus={()=>{setShowPreset(false)}} onBlur={handleBlur} onChange={handleInputChange} value={inputMessage} rows={200}>
                </TextArea>
                {showPreset && <ReasoningPreset className="preset-body" handleClick={handleClick}/>}
                {inputMessage &&
                    <div className="reasoning-clear-input-button-wrapper">
                        <Button className='clear-button' onClick={handleClearInput}>
                        Clear All
                        <DeleteOutlined style={{color:'#A72841'}}/>
                        </Button>
                    </div>
                }
                <Button
                    className='reasoning-send-button'
                    type="text"
                    shape="circle"
                    loading={reasoningGenerating}
                    icon={<SendSvg />}
                    size='large'
                    onClick={handleSubmit}/>
            </div>
            <div className='reasoning-right-section'>
                <div className='reasoning-output' id='reasoning-output'>
                    <ReactMarkdown children={output}/>
                    {!reasoningGenerating && output ?
                    <>
                    <div className='feedback-icon'>{
                            <div>
                            <Icon onClick={handleGoodFeedback} className='feedback-icon-item' size='13' style={{'paddingRight':'5px'}} color={feedback.type === 'good'?'#A72841':'grey'} icon={GoodSvg}/>
                            <Icon onClick={handleBadFeedback} className='feedback-icon-item' size='13' color={feedback.type === "bad"?'#A72841':'grey'} icon={BadSvg}/>
                            </div>}
                        </div>
                        {openFeedback && <FeedbackPopup prompt={inputMessage} answer={output} feedbackType={feedback.type} open={openFeedback} setOpen={setOpenFeedback} feedback={feedback} setFeedback={setFeedback} mode={"reasoning"}/>}
                    <div className="clear-button-wrapper">
                        <Button className='clear-button' onClick={() => setOutput()}>
                          Clear history
                          <DeleteOutlined style={{color:'#A72841'}}/>
                        </Button>
                    </div>
                    </>:null}
                </div>
            </div>
        </div>
  )
}

export default Reasoning