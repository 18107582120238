import React, { useState } from "react";
import ReactGA from "react-ga4";
import './FeedbackPopup.css'
import TextArea from "antd/es/input/TextArea";
import stopWords from "../utils/stopWords";

const FeedbackPopup = ({feedbackType, open, setOpen, messages, prompt, answer, index, feedback, setFeedback, mode})=>{
    const handleSubmitFeedback = ()=>{
        setOpen(false)
        // let original_prompt = ""
        // let original_answer = ""
        if(mode === "consultant" || mode === "retrieval") {
            messages[index].feedback.detail = feedbackContent
            // original_prompt = messages[index - 1] || "";
            // original_answer = messages[index] || "";
            // console.log(messages)
        } else if (mode === "reasoning" || mode === "drafting" || mode === "analysing") {
            setFeedback({type:feedbackType, detail:feedbackContent, prompt:prompt, answer:answer, mode:mode})
            // original_prompt = prompt;
            // original_answer = answer;
            // console.log("feedback: ")
            // console.log(
            //     {type:feedbackType, detail:feedbackContent, prompt:prompt, answer:answer, mode:mode}
            // )
        } else {
            console.warning('invalid mode parameter')
        }

        // const feedbackToSend = JSON.stringify({detail:feedbackContent, original_prompt:original_prompt, original_answer:original_answer})
        const shortenedFeedback = shortenFeedback(feedbackContent)
        // Collect feedback using Google Analysis
        let feedbackNotation = '+'
        if (feedbackType === 'bad') {
            feedbackNotation = '-'
        }
        ReactGA.event({
            category: "User Submitted Feedback for page " + mode,
            action: "Feedback Submitted",
            label: feedbackNotation + shortenedFeedback, // "good" or "bad"
            value: feedbackContent.length,
        });
    }

    const shortenFeedback = (text) => {
        // Remove common stop words
        const loadedStopWords = new Set(stopWords);
        const words = text.split(/\s+/).filter(word => !loadedStopWords.has(word.toLowerCase()));
    
        // Sort by word length (shorter words first)
        words.sort((a, b) => a.length - b.length);
    
        // Pick the most meaningful words (prioritizing shorter ones)
        let result = words.slice(0, 2).join(" ");
    
        // Ensure ≤10 characters
        if (result.length > 30) {
            result = result.substring(0, 9);
        }
    
        return result;
    };

    const [feedbackContent, setFeedbackContent] = useState("")
    return(
    <div>
        <div className='mask-feedback'></div>
        {feedbackType === 'good'?
            <div className="popup-wrapper-feedback">
                {/* <Icon onClick={handleClosePopup} size='20' icon={CloseSvg} className="close-popup-btn"/> */}
                <div className="feedback-title">Provide Additional Feedback</div>
                <div className="feedback-box" ><TextArea className="feedback-box-content" style={{resize:'none'}}  placeholder="What do you like about the response" onChange={(msg)=>{setFeedbackContent(msg.target.value)}} value={feedbackContent}/></div>
                <button className="feedback-submit-button" onClick={handleSubmitFeedback}>Submit feedback</button>
                <button className="feedback-cancle-button" onClick={handleSubmitFeedback}>No, thanks</button>
            </div>:
            <div className="popup-wrapper-feedback">
                <div className="feedback-title">Provide Additional Feedback</div>
                <div className="feedback-box" ><TextArea className="feedback-box-content" style={{resize:'none'}}  placeholder="What is the issue with the response? How could it be improved?" onChange={(msg)=>{setFeedbackContent(msg.target.value)}} value={feedbackContent}/></div>
                <button className="feedback-submit-button" onClick={handleSubmitFeedback}>Submit feedback</button>
                <button className="feedback-cancle-button" onClick={handleSubmitFeedback}>No, thanks</button>
            </div>
        }
    </div>
    )
}

export default FeedbackPopup