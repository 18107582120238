import { Button, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import "./Drafting.css"
import { ReactComponent as SendSvg } from '../assets/icons/send.svg';
import SubHeader from './SubHeader';
import AppFooter from './Footer';
import ReactMarkdown from 'react-markdown';
import ReasoningPreset from './ReasoningPreset';
import { DeleteOutlined} from '@ant-design/icons';
import { ReactComponent as GoodSvg } from '../assets/icons/GoodSvg.svg';
import { Icon } from "../utils/Icon";
import {ReactComponent as BadSvg} from '../assets/icons/BadSvg.svg';
import FeedbackPopup from './FeedbackPopup';

const { TextArea } = Input;

const GET_PARTIES_PROMPT = "Please give me a list of parties with description involved in the following case. ";
const GET_BACKGROUND_PROMPT = "Please give me a background description of the following case. ";
const GET_SUMMARY_PROMPT = "Please give me a concise summary of the following case. ";

const Drafting = ({result, setResult, input, setInput, draftingGenerating, setDraftingGenerating, feedback, setFeedback}) => {
    // eslint-disable-next-line
    const [currentTemplate, setCurrentTemplate] = useState("Template: Statement of Claim");
    const [showPreset, setShowPreset] = useState(true);
    const [openFeedback, setOpenFeedback] = useState(false);
    useEffect(()=>{
        if (input !== "") {
            setShowPreset(false)
        }
    },[input]);
    const handleClick=(text)=>{
        setInput(text)
    }
    const handleBlur = ()=>{
        if (input === "") {
            setShowPreset(true)
        }
    }

    const handleChange = (value)=>{
        setCurrentTemplate(value)
    }

    // const simulateTypingEffect = (text, delay = 50, setText) => {
    //     let index = 0;
    //     function type() {
    //         if (index < text.length) {
    //             setText(prev => prev + text[index]);
    //             index++;
    //             setTimeout(type, delay);
    //         }
    //     }
    //     type();
    // };
    
    // Simulate Typing Effect
    // const handleSubmit = () => {
    //     setFeedback({ type: '', detail: '' });
    //     const drafting_url = process.env.REACT_APP_GET_REASONING_RESPONSE;
        
    //     const partiesInput = { "query": GET_PARTIES_PROMPT + input, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false };
    //     const backgroundInput = { "query": GET_BACKGROUND_PROMPT + input, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false };
    //     const analyseInput = { "query": input, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false };
    //     const summaryInput = { "query": GET_SUMMARY_PROMPT + input, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false };
    
    //     setDraftingGenerating(true);
    //     setResult("");
    
    //     const fetchAndType = async (fetchOptions, sectionTitle) => {
    //         setResult(prev => prev + `\n\n**${sectionTitle}**\n\n`);
    //         try {
    //             const response = await fetch(drafting_url, fetchOptions);
    //             const reader = response.body.getReader();
    //             const decoder = new TextDecoder();
    //             let fullText = "";
    
    //             async function read() {
    //                 const { done, value } = await reader.read();
    //                 if (done) {
    //                     return;
    //                 }
    //                 fullText += decoder.decode(value);
    //                 await read();
    //             }
    
    //             await read();
    //             simulateTypingEffect(fullText, 5, setResult);
    //         } catch (error) {
    //             console.error(`Error fetching ${sectionTitle}:`, error);
    //             setResult(prev => prev + `Sorry, the request for ${sectionTitle} content failed. Please try again later.`);
    //         }
    //     };
    
    //     (async () => {
    //         await fetchAndType({ 
    //             method: 'POST', 
    //             headers: { 'Content-Type': 'application/json' }, 
    //             body: JSON.stringify(partiesInput) 
    //         }, "Parties");
    
    //         await fetchAndType({ 
    //             method: 'POST', 
    //             headers: { 'Content-Type': 'application/json' }, 
    //             body: JSON.stringify(backgroundInput) 
    //         }, "Background");
    
    //         await fetchAndType({ 
    //             method: 'POST', 
    //             headers: { 'Content-Type': 'application/json' }, 
    //             body: JSON.stringify(analyseInput) 
    //         }, "Analysis");
    
    //         await fetchAndType({ 
    //             method: 'POST', 
    //             headers: { 'Content-Type': 'application/json' }, 
    //             body: JSON.stringify(summaryInput) 
    //         }, "Summary");
    
    //         setDraftingGenerating(false);
    //     })();
    // };
    

    // DO NOT REMOVE - with chunk
    const handleSubmit = ()=>{
        setFeedback({type:'', detial:''})
        const drafting_url = process.env.REACT_APP_GET_REASONING_RESPONSE;
        const partiesInput = {"query": GET_PARTIES_PROMPT + input, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false}
        const backgroundInput = {"query": GET_BACKGROUND_PROMPT + input, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false}
        const analyseInput = {"query": input, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false}
        const summaryInput = {"query": GET_SUMMARY_PROMPT + input, "uid": "uid", "subSystem": "subSystem", "isSourceNeeded": false}
        
        setDraftingGenerating(true);
        setResult("");
        const fetchPartiesOptions = {
          method: 'POST',
          headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(partiesInput),
        };
        const fetchBackgroundOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(backgroundInput),
        }
        const fetchAnalysisOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(analyseInput)
        }
        const fetchSummaryOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(summaryInput),
        }
        setResult('**Parties**\n\n');
        fetch(drafting_url, fetchPartiesOptions).then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            function read() {
                return reader.read().then(async ({ done, value }) => {
                    if (done) {
                        return;
                    }
                    const chunk = decoder.decode(value);
                    setResult(prevResult => prevResult + chunk);
                    return read();
                });
            }
            return read();
        }).catch(error => {
            console.error('Error:', error);
            setResult(prevResult => prevResult + 'Sorry, the request for Parties content fails. Please try agian later.');
        }).then(() => {
            // After the first request is finished, initiate the second request
            setResult(prevResult => prevResult+ '\n\n**Background**\n\n');
            return fetch(drafting_url, fetchBackgroundOptions);
        }).then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            function read() {
                return reader.read().then(async ({ done, value }) => {
                    if (done) {
                        return;
                    }
                const chunk = decoder.decode(value);
                setResult(prevResult => prevResult + chunk);
                return read();
                });
            }
            return read();
        }).catch(error => {
            console.error('Error:', error);
            setResult(prevOutput => prevOutput + 'Sorry, the request for Background content fails. Please try agian later.');
        }).then(() => {
            setResult(prevResult => prevResult + '\n\n**Analysis**\n\n');
            return fetch(drafting_url, fetchAnalysisOptions);
        }).then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            function read() {
                return reader.read().then(async ({ done, value }) => {
                    if (done) {
                        return;
                    }
                const chunk = decoder.decode(value);
                setResult(prevResult => prevResult + chunk);
                return read();
                });
            }
            return read();
        }).catch(error => {
            console.error('Error:', error);
            setResult(prevOutput => prevOutput + 'Sorry, the request for Analysis content fails. Please try agian later.');
        }).then(() => {
            setResult(prevResult => prevResult + '\n\n**Summary**\n\n');
            return fetch(drafting_url, fetchSummaryOptions);
        }).then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            function read() {
                return reader.read().then(async ({ done, value }) => {
                    if (done) {
                        setDraftingGenerating(false);
                        return;
                    }
                const chunk = decoder.decode(value);
                setResult(prevResult => prevResult + chunk);
                return read();
                });
            }
            return read();
        }).catch(error => {
            console.error('Error:', error);
            setResult(prevOutput => prevOutput + 'Sorry, the request for Analysis content fails. Please try agian later.');
            setDraftingGenerating(false);
        })
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };
    useEffect(()=>{
        var drafting_output = document.getElementById('drafting-output')
        if (drafting_output.scrollHeight > drafting_output.clientHeight) {
            drafting_output.scrollTop = drafting_output.scrollHeight
        }
    },[result])

    const handleClearInput = ()=>{
        setInput("")
        setShowPreset(true)
    }

    const handleGoodFeedback = ()=>{
        if (feedback.type !== 'good') {
            feedback.type = 'good';
            setOpenFeedback(true)
        }
      }
    
    const handleBadFeedback = () => {
        if (feedback.type !== 'bad') {
            feedback.type = 'bad';
            setOpenFeedback(true)
        }
    }

    return (
        <div className='drafting-container'>
            <SubHeader/>
            <div className='left-section'>
                <TextArea id='drafting-input'
                    value={input}
                    onBlur={handleBlur}
                    onFocus={()=>{setShowPreset(false)}}
                    onChange={handleInputChange}
                    style={{resize:'none'}}
                    className='drafting-input'
                    rows={200}>
                </TextArea>
                {showPreset && <ReasoningPreset className="preset-body" handleClick={handleClick}/>}
                {input &&
                    <div className="drafting-clear-input-button-wrapper">
                        <Button className='clear-button' onClick={handleClearInput}>
                          Clear All
                          <DeleteOutlined style={{color:'#A72841'}}/>
                        </Button>
                    </div>
                }
                <Button
                    className='drafting-send-button'
                    type="text"
                    shape="circle"
                    loading={draftingGenerating}
                    icon={<SendSvg />}
                    size='large'
                    onClick={handleSubmit}/>
            </div>
            <div className='right-section'>
                <Select
                    className='drafting-select'
                    bordered={false}
                    defaultValue="Template: Statement of Claim"
                    onChange={handleChange}
                    options={[
                        { value: 'Template: Statement of Claim', label: 'Template: Statement of Claim' },
                        { value: 'Template: Other', label: 'Template: Other' }
                    ]}
                />
                <div className='drafting-output' id="drafting-output">
                    <ReactMarkdown children={result}/>
                    {!draftingGenerating && result ?
                    <>
                    <div className='feedback-icon'>{
                            <div>
                            <Icon onClick={handleGoodFeedback} className='feedback-icon-item' size='13' style={{'paddingRight':'5px'}} color={feedback.type === 'good'?'#A72841':'grey'} icon={GoodSvg}/>
                            <Icon onClick={handleBadFeedback} className='feedback-icon-item' size='13' color={feedback.type ==="bad"?'#A72841':'grey'} icon={BadSvg}/>
                            </div>}
                        </div>
                        {openFeedback && <FeedbackPopup prompt={input} answer={result} feedbackType={feedback.type} open={openFeedback} setOpen={setOpenFeedback} feedback={feedback} setFeedback={setFeedback} mode={"drafting"}/>}
                    <div className="clear-button-wrapper">
                        <Button className='clear-button' onClick={() => setResult()}>
                          Clear history
                          <DeleteOutlined style={{color:'#A72841'}}/>
                        </Button>
                    </div>
                    </>:null}
                </div>
            </div>
            <AppFooter/>
        </div>
    )
}

export default Drafting